<template>
  <div class="newsdetails">
    <Head />
    <TitleBg />
    <NewsList />
    <Floor />
  </div>
</template>
<script>
import Head from '@/views/Head'
import Floor from '@/views/Floor'
import TitleBg from './TitleBg'
import NewsList from './NewsList'

export default ({
  name: "NewsDetails",
  components: {
    Head,
    Floor,
    TitleBg,
    NewsList
  }
})
</script>

<style lang="scss" scoped>
.newsdetails{
  position:absolute;
  left: 50%;
  transform: translateX(-50%);
}
</style>
