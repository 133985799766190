<template>
  <div class="newsList">
    <div class="list">
      <ul class="listul">
        <li v-for="news in list" :key="news.id" class="listli" @click="clickNews(news)">
          <img class="reqPic" :src="news.cover">
          <!-- <img class="reqPic" src="@/assets/image/pc/newsdetails/default.png"> -->
          <div class="newskind" :style="{'background':news.color}"><p>{{ news.tag }}</p></div>
          <p class="newstitle">{{ news.title }}</p>
          <img class="split" src="@/assets/image/pc/newsdetails/线.png">
          <p class="newsdescription">{{ news.description }}</p>
          <p class="date">{{ news.createTime|dateFilters }}</p>
        </li>
      </ul>
      <div class="pageSize">
        <van-pagination v-model="page" :total-items="count" :show-page-size="5" :items-per-page="queryForm.pageSize">
          <template #prev-text>
            <van-icon name="arrow-left" />
            <p>Prex</p>
          </template>
          <template #next-text>
            <p>Next</p>
            <van-icon name="arrow" />
          </template>
          <template #page="{ text }">{{ text }}</template>
        </van-pagination>
      </div>
      <!-- <div class="pageSize">
        <ul>
          <li>1</li>
          <li>2</li>
          <li>3</li>
          <li>4</li>
          <li>5</li>
        </ul>
        <div class="nextButton">
          <p>Next</p>
          <img src="@/assets/image/pc/newsdetails/箭头.png">
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { reqGetNewsList, reqGetSetting } from '@/api/news'

export default ({
  name: "NewsList",
  filters: {
    dateFilters(str) {
      const date = new Date(str).format("yyyy-MM-dd");
      return date
    }
  },
  data() {
    return {
      newsList: [],
      list: [],
      loading: false,
      finished: false,
      queryForm: {
        channel: 'gg.com',
        pageSize: 5,
        currentPage: 1,
        hot: 1
      },
      querySetting: {
        key: "article_type"
      },
      newsKindMap: {},
      newsKindColorMap: {},
      count: 0,
      page: 1
    }
  },
  watch: {
    page(n, o) {
      // console.log(n)
      // console.log(o)
      this.changePage(n, o)
    }
  },
  created() {
    this.onLoad()
  },
  methods: {
    async onLoad() {
      this.loading = true;
      const res = await reqGetNewsList(this.queryForm)
      const resSetting = await reqGetSetting(this.querySetting)
      console.log(res.data)
      this.count = res.count
      console.log(this.count)
      // console.log(resSetting.data)
      // console.log(resSetting.data.length)
      const resSettingJson = JSON.parse(resSetting.data)
      // console.log(resSettingJson)
      // eslint-disable-next-line no-array-constructor
      var mapList = new Array();
      for (var i = 0; i < resSettingJson.length; i++) {
        // console.log(settingList)
        if (resSettingJson[i].key === "gg.com") {
          mapList = resSettingJson[i].value
          break;
        }
      }
      // console.log(mapList)
      var newsKindMap = new Map();
      var newsKindColorMap = new Map();
      for (var j = 0; j < mapList.length; j++) {
        newsKindMap.set(mapList[j].value, mapList[j].label)
        if (mapList[j].value === "news") {
          newsKindColorMap.set(mapList[j].value, "#2394C5")
        } else if (mapList[j].value === "gameWalkthrough") {
          newsKindColorMap.set(mapList[j].value, "#6A22CD")
        } else if (mapList[j].value === "announcement") {
          newsKindColorMap.set(mapList[j].value, "#EB3644")
        }
      }
      // console.log(newsKindMap)
      for (var k = 0; k < res.data.length; k++) {
        if (newsKindMap.has(res.data[k].tag)) {
          res.data[k].color = newsKindColorMap.get(res.data[k].tag)
          res.data[k].tag = newsKindMap.get(res.data[k].tag)
        }
      }
      // console.log(res.data)
      // this.list = [...this.list, ...res.data]
      this.list = [...res.data]
      // this.queryForm.currentPage = res.currentPage + 1
      this.newsKindMap = newsKindMap
      this.newsKindColorMap = newsKindColorMap
      this.page = res.currentPage
      this.loading = false;
      if (this.list.length >= res.count) {
        this.finished = true;
      }
    },
    clickNews({ id }) {
      this.$router.push({
        path: '/newsdetailspage',
        query: {
          id
        }
      })
    },
    changePage(newPage, oldPage) {
      this.queryForm.currentPage = newPage
      this.onLoad()
    }
  }
})
</script>

<style lang="scss" scoped>
.newsList{
  .list{
    position: relative;
    background: #E7E6E3;
    width: 100%;
    height: 1354px;
    .listul{
      position: absolute;
      margin-top: 48px;
      margin-left: 537px;
      width: 850px;
      height: 1066px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .listli{
        position: relative;
        margin-top: 51px;
        height: 173px;
        width: 850px;
        .reqPic{
          cursor: pointer;
          width: 305px;
          height: 172px;
          display: inline;
          float: left;
        }
        .newskind{
          margin-left: 351px;
          margin-top: 20px;
          width: 52px;
          height: 22px;
          background: #D0383C;
          p{
            text-align: center;
            padding-top: 2px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 18px;
          }
        }
        .newstitle{
          cursor: pointer;
          margin-left: 351px;
          margin-top: 26px;
          width: 500px;
          text-align: left;
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #2B2A29;
          line-height: 39px;
          overflow:hidden;
          white-space:nowrap;
          text-overflow:ellipsis;
        }
        .split{
          position: absolute;
          margin-top: 3px;
          left: 351px;
          width: 493px;
          height: 1px;
          background: #000000;
        }
        .newsdescription{
          margin-top: 12px;
          margin-left: 351px;
          width: 494px;
          overflow:hidden;
          white-space:nowrap;
          text-overflow:ellipsis;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #656462;
          line-height: 26px;
        }
        .date{
          margin-top: 6px;
          text-align: right;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #21211D;
          line-height: 52px;
        }
      }
    }
  }
  // .pageSize{
  //   height: 15px;
  //   width: 500px;
  //   position: absolute;
  //   .arrow{
  //     position: absolute;
  //     top: 1253px;
  //     left: 1050px;
  //     width: 80px;
  //     height: 21px;
  //     p{
  //       position: absolute;
  //       width: 50px;
  //       text-align: left;
  //       font-size: 18px;
  //       font-family: Arial;
  //       font-weight: bold;
  //       color: #A6A5A3;
  //     }
  //   }
  //   ul{
  //     position: absolute;
  //     top: 1253px;
  //     left: 855px;
  //     width: 150px;
  //     display: flex;
  //     justify-content: space-around;
  //     li{
  //       font-size: 18px;
  //       font-family: Arial;
  //       font-weight: bold;
  //       color: #A6A5A3;
  //     }
  //   }
  //   .nextButton{
  //     position: absolute;
  //     top: 1253px;
  //     left: 1050px;
  //     width: 80px;
  //     height: 21px;
  //     p{
  //       position: absolute;
  //       width: 50px;
  //       text-align: left;
  //       font-size: 18px;
  //       font-family: Arial;
  //       font-weight: bold;
  //       color: #A6A5A3;
  //     }
  //     img{
  //       position: absolute;
  //       margin-top: 3px;
  //       margin-left: 5px;
  //       width: 8px;
  //       height: 14px;
  //     }
  //   }
  // }
}
</style>
<style lang="scss">
  .pageSize{
    height: 42px;
    width: 500px;
    position: absolute;
    top: 1253px;
    left: 700px;
    .van-pagination__item{
      background-color: transparent;
      font-size: 18px;
      font-family: Arial;
      font-weight: bold;
      color: #A6A5A3;
      p{
        cursor: pointer;
      }
    }
    .van-pagination__item--active{
      font-size: 25px;
    }
    .van-pagination__item--disabled{
      opacity: 0;
    }
  }
</style>
